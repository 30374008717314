import React, { Component } from "react"; import './App.css';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';


class addRole extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    submitForm(values) {
        this.props.addRole(values);
    }


    render() {

        return (
            <div id="addRole">
                <h1>Ajouter un rôle</h1>
                <Formik
                    initialValues={{
                        name: '',
                        description: '',
                        directed: false,
                    }}

                    validationSchema={
                        Yup.object({
                            name: Yup.string()
                                .min(1)
                                .max(25)
                                .required('Required'),
                            description: Yup.string()
                                .min(1)
                                .max(500)
                        })
                    }
                    onSubmit={async (values, actions) => {
                        this.submitForm(values);
                        actions.resetForm({
                            values: { name: '', description: '' },
                        });
                    }}>{({ values }) => (
                        <Form className="addRoleForm">
                            <Field id="name" name="name" placeholder="Nom du rôle" className="name" autoFocus min="1" disabled={!this.props.admin} /><br />

                            <Field id="description" as="textarea" name="description" placeholder="Description du rôle" className="description" min="1" disabled={!this.props.admin} /><br />

                            <div>
                                <label for="directed" className="checkboxContainer"><Field type="checkbox" name="directed" id="directed" /><span class="checkmark"></span>Ciblé vers un autre joueur</label><br />
                            </div>
                            <button type="submit" className="submit" disabled={!this.props.admin}>Ajouter</button>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

export default addRole;
