import io from 'socket.io-client';

let socket;


export const initiateSocket = (room, username) => {

    let serverUrl = 'http://localhost:4200/'
    if (process.env.NODE_ENV === 'development') serverUrl = 'http://localhost:4200/'
    else if (process.env.NODE_ENV === 'production') serverUrl = window.location.protocol + '//' + window.location.host + '/'

    socket = io(serverUrl);

    console.log(`Connecting socket...`);
    if (socket && room) {
        socket.emit('join', room, username);
        // console.log(socket, room)
    }

    // socket.on('ping', msg => {
    //     console.log(msg);
    // });
    // socket.on('connect_error', (error) => {
    //     console.log(error)
    // });
    socket.on('disconnect', (reason) => {
        console.log('reason ' + reason)
        socket.emit('join', room, username);
    });
}
export const disconnectSocket = (room) => {
    console.log('Disconnecting socket...');
    if (socket && room) {
        console.log(room)
        socket.emit('disconnect', room);
        socket.disconnect();

    }
}
export const subscribeToJoin = (cb) => {
    if (!socket) return (true);
    socket.on('userjoined', players => {
        console.log('Websocket event received! join', players);
        return cb(null, players);
    });
}
export const subscribeToRoles = (cb) => {
    if (!socket) return (true);
    socket.on('rolesUpdated', roles => {
        // console.log('Websocket event received! roles');
        return cb(null, roles);
    });
}
export const subscribeToRole = (cb) => {
    if (!socket) return (true);
    socket.on('role', role => {
        console.log('Websocket event received! role');
        return cb(null, role);
    });
}
export const subscribeToAdmin = (cb) => {
    if (!socket) return (true);
    socket.on('admin', admin => {
        console.log('Websocket event received! admin');
        return cb(null, admin);
    });
}
// export const sendMessage = (room, message, username) => {
//     if (socket) socket.emit('chat', { message, room, username });
// }


// export const subscribeToSettings = (cb) => {
//     if (!socket) return (true);
//     socket.on('setting', msg => {
//         return cb(null, msg);
//     });
// }
// export const updateSettings = (room, field, value) => {
//     if (socket) socket.emit('setting', { field, value, room });
// }

export const sendRole = (room, values) => {
    if (socket) socket.emit('role', { room, values });
}
export const deleteRole = (room, role) => {
    if (socket) socket.emit('deleteRole', { room, role });
}
export const askToRoll = (room, values) => {
    if (socket) socket.emit('askToRoll', { room, values });
}
// export const subscribeToGame = (cb) => {
//     if (!socket) return (true);
//     socket.on('game', msg => {
//         return cb(null, msg);
//     });
// }



// export const requestGameStart = (room, options) => {
//     if (!socket) return (true);
//     socket.emit('startGame', { room, options })
// }
// export const requestGameReset = (room) => {
//     if (!socket) return (true);
//     socket.emit('resetGame', room)
// }
// export const sendAnswer = (room, message) => {
//     if (socket) socket.emit('game', { message, room });
// }