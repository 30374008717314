import React, { Component } from "react"; import './App.css';
import confetti from 'canvas-confetti';
import {
    initiateSocket,
    subscribeToJoin, subscribeToRoles, subscribeToRole, subscribeToAdmin, deleteRole, sendRole, askToRoll
} from './Socket';
import PlayerList from './PlayerList';
import RoleList from './RoleList';
import UserRole from './UserRole';
import AddRole from './AddRole';
import Roll from './Roll';



class Room extends Component {
    constructor(props) {
        super(props);
        this.addRole = this.addRole.bind(this);
        this.deleteRole = this.deleteRole.bind(this);
        this.roll = this.roll.bind(this);
        this.state = {
            playerList: [],
            roleList: [],
            userRole: '',
            admin: true,
            room: 1
        }
    }
    componentDidMount() {
        initiateSocket(this.state.room, this.props.name);

        subscribeToJoin((err, data) => {
            if (err) return;
            this.setState({ playerList: data.players })
        });
        subscribeToRoles((err, data) => {
            if (err) return;
            this.setState({ roleList: data.roles })
        });
        subscribeToAdmin((err, data) => {
            if (err) return;
            this.setState({ admin: true })
        });
        subscribeToRole((err, data) => {
            if (err) return;
            this.launchConfetti();
            this.setState({ userRole: data })
        });
    }


    launchConfetti() {
        var count = 200;
        var defaults = {
            origin: { y: 0.7 },
            zIndex: 10,
            colors: ['#CDE7B0', '#FE938C', '#B4ADEA']
        };

        function fire(particleRatio, opts) {
            confetti(Object.assign({}, defaults, opts, {
                particleCount: Math.floor(count * particleRatio)
            }));
        }

        fire(0.25, {
            spread: 35,
            startVelocity: 55,
        });
        fire(0.2, {
            spread: 90,
        });
        fire(0.35, {
            spread: 120,
            decay: 0.91,
            scalar: 0.8
        });
        fire(0.1, {
            spread: 150,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
        });
        fire(0.1, {
            spread: 160,
            startVelocity: 45,
        });
    }

    submitForm(values) {
        this.props.registerName(values.name);
    }

    addRole(values) {
        sendRole(this.state.room, values)
    }
    deleteRole(role) {
        deleteRole(this.state.room, role)
    }
    roll(values) {
        askToRoll(this.state.room, values)
    }

    render() {
        return (
            <div id="room">
                <PlayerList playerList={this.state.playerList} />
                <div className="centralPanel">
                    <UserRole role={this.state.userRole} />

                    {this.state.admin ?
                        <Roll admin={this.state.admin} roll={this.roll} />
                        : null}
                </div>



                <div className="rightPanel">
                    {this.state.admin ?
                        <AddRole admin={this.state.admin} addRole={this.addRole} />
                        : null}
                    <RoleList roleList={this.state.roleList} deleteRole={this.deleteRole} />

                </div>

            </div>
        )
    }
}

export default Room;
