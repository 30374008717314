import React, { Component } from "react"; import './App.css';

class UserRole extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {

        const role = this.props.role;

        return (
            <div id="userRole">
                {role !== '' ?

                    <div className="roleCard">
                        <span className="preRole">Votre rôle…</span>
                        <section>
                            <span className="role">{role.name}</span>
                            {role.directed ?
                                <span className="cible">{role.directed}</span>
                                : null}

                            {role.description !== '' ?
                                <span className="description">{role.description}</span>
                                : null}
                        </section>
                    </div>
                    : <span className="placeholder">Vous n'avez pas encore de role attribué</span>}
            </div>
        )
    }
}

export default UserRole;
