import React, { Component } from "react"; import './App.css';

import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

class Roll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultMin: 2,
            defaultMax: 5
        }

    }


    submitForm(values) {
        this.setState({ defaultMin: values.rolesMin, defaultMax: values.rolesMax })
        this.props.roll(values);
    }

    render() {

        return (
            <Formik
                initialValues={{
                    rolesMin: this.state.defaultMin,
                    rolesMax: this.state.defaultMax,
                }}

                validationSchema={
                    Yup.object({
                    })
                }
                onSubmit={async (values, actions) => {
                    this.submitForm(values);
                }}>{({ values }) => (
                    <Form id="roll">


                        <div className="minMaxContainer">
                            <label for="rolesMin">
                                Attribuer entre
                                </label>
                            <Field id="rolesMin" name="rolesMin" type="number" placeholder="0" className="input" autoFocus min="0" />

                            <label for="rolesMax">
                                et
                                </label>
                            <Field id="rolesMax" name="rolesMax" type="number" placeholder="0" className="input" autoFocus min="0" />
                            <label>roles</label>
                        </div>

                        <button type="submit" className="submit" disabled={!this.props.admin}>Attribuer les rôles</button>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default Roll;
