import React, { Component } from "react"; import './App.css';

class PlayerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {

        const playerList = this.props.playerList;
        return (
            <div id="playerList">
                <h1>Joueurs</h1>
                {Object.keys(playerList).map((player) =>
                    <li key={playerList[player].socketId} className="tile">
                        <span className="name">{playerList[player].name}</span>
                    </li>
                )}
            </div>
        )
    }
}

export default PlayerList;
