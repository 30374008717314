import React, { Component } from "react"; import './App.css';

class RoleList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }


    render() {

        const roleList = this.props.roleList;

        return (
            <div id="roleList">
                <h1>Roles</h1>


                {!roleList ? <span className="placeholder">Aucun rôle défini</span> :

                    Object.keys(roleList).map((role) =>
                        <li key={role} className="tile">

                            <span className={roleList[role].directed ? 'name directed' : 'name'}>{roleList[role].name}</span>
                            {roleList[role].description !== '' ?
                                <span className="description">{roleList[role].description}</span>
                                : null}
                            <span className="delete" onClick={this.props.deleteRole.bind(null, role)}>Supprimer</span>
                        </li>
                    )

                }
            </div>
        )
    }
}

export default RoleList;
