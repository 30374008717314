import React, { Component } from "react"; import './App.css';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

class SetName extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }
    submitForm(values) {
        this.props.registerName(values.name);
    }

    render() {


        return (
            <Formik
                initialValues={{
                    name: ''
                }}

                validationSchema={
                    Yup.object({
                        name: Yup.string()
                            .min(1)
                            .max(25)
                            .required('Required'),
                    })
                }
                onSubmit={async (values, actions) => {
                    this.submitForm(values);
                    actions.resetForm({
                        values: { name: '' },
                    });
                }}>
                <Form className="usernameForm">
                    <div className="title">
                        <span>Bienvenue sur</span>
                        <p>Le Magnifique Attributeur<br />de Rôles de Bruno</p>

                        <span>Pour commencer, veuillez entrer votre nom</span>
                    </div>
                    <div className="nameForm">
                        <Field id="name" name="name" placeholder="Votre nom" className="input" autoFocus min="1" />
                        <button type="submit" className="submit">Entrer</button>
                    </div>
                </Form>
            </Formik>
        )
    }
}

export default SetName;
