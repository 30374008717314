import React, { Component } from "react"; import './App.css';
import SetName from './SetName';
import Room from './Room';

class App extends Component {
  constructor(props) {
    super(props);
    this.registerName = this.registerName.bind(this);
    this.state = {
      name: undefined,
    }

  }
  registerName(name) {
    this.setState({ name: name });
  }

  render() {
    console.log(this.state.name)
    if (this.state.name != null) {
      return (
        <div className="App">
          <Room name={this.state.name} />
        </div>
      )
    } else {
      return (
        <div className="App">
          <SetName registerName={this.registerName} />
        </div>
      )
    }
  }
}

export default App;
